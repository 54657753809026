<template>
  <div>
    <title-bar
      submitBtn
      :submitBtnTitle="$t('review.update')"
      :title="$t('review.update')"
      @submitPressed="submitPressed"
    />
    <reviews-form
      v-if="review"
      :reviewObject="review"
      :action="action"
      @clearAction="action = null"
    />
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import ReviewsForm from "./components/ReviewsForm.vue";

export default {
  components: {
    TitleBar,
    ReviewsForm,
  },
  data() {
    return {
      review: null,
      action: null,
    };
  },
  created() {
    this.loadReview();
  },
  methods: {
    loadReview() {
      const id = this.$route.params.id;
      this.$Reviews.getResource({ id }).then((response) => {
        this.review = response.data;
        this.review.stars = {
          label: this.$helper.getEnumTranslation('review_stars', this.review.stars, this.$i18n.locale),
          id: this.review.stars
        }
      });
    },
    submitPressed() {
      this.action = "update";
    },
  },
};
</script>
